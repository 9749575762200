<template>
  <div v-if="filtersActive">
    <div v-for="(filter, index) in $store.state.part.filters"
         v-bind:key="`${index}_${filter.type}`">
      <div v-if="filter.name" class="part__filter">
        <I5Button
          :isSwitch="true"
          :active="filtersActive[index]"
          @click="filterClick(index, !filtersActive[index])"
          type="filter"
          fontSize="15px"
          :text="filter.name" />
        <div class="part__filter_container"
             v-if="filter.type === 'checkbox_list' && filtersActive[index]">
          <div v-for="(item, index) in filter.values" v-bind:key="`${index}_${item}`">
            <div class="part__filter_checkbox">
              <div>{{`${item.name} (${item.count})`}}</div>
              <I5CheckBox @click="addFilter('technology', item.value, $event, 'array')" />
            </div>
          </div>
        </div>
        <div class="part__filter_container" v-if="filter.type === 'stars' && filtersActive[index]">
          <div class="block block__center">
            <Stars editable :rating="rating" @click="setRating($event)" />
          </div>
          <div class="part__filter_rating_wrapper" v-if="rating">
            <div class="part__filter_rating_value">
              {{ rating }}
            </div>
          </div>
        </div>
        <div class="part__filter_container part__filter_region_wrapper"
             v-if="filter.type === 'country' && filtersActive[index]">
          <div v-for="(item, index) in filter.values" v-bind:key="index">
            <div class="part__filter_text part__filter_region_item"
                 v-if="index <= 5 || showAllRegions === true">
              <div class="part__filter_checkbox">
                <div>{{`${item.name} (${item.count})`}}</div>
                <I5CheckBox @click="addFilter('country', item.value, $event, 'array')" />
              </div>
            </div>
          </div>
          <div>
            <div
              class="part__filter_region_more"
              @click="setShowRegions(true)"
              v-if="!showAllRegions && filter.values.length >= 5">
              {{part.show_all[language]}}
            </div>
            <div class="part__filter_region_more"
                 @click="setShowRegions(false)"
                 v-if="showAllRegions">
              {{part.hide[language]}}
            </div>
          </div>
        </div>
        <div class="part__filter_container part__filter_region_wrapper"
             v-if="filter.type === 'region' && filtersActive[index]">
          <div v-for="(item, index) in filter.values" v-bind:key="index">
            <div class="part__filter_text part__filter_region_item"
                 v-if="index <= 5 || showAllRegions === true">
              <div class="part__filter_checkbox">
                <div>{{`${item.name} (${item.count})`}}</div>
                <I5CheckBox @click="addFilter('region', item.value, $event, 'array')" />
              </div>
            </div>
          </div>
          <div>
            <div
              class="part__filter_region_more"
              @click="setShowRegions(true)"
              v-if="!showAllRegions && filter.values.length >= 5">
              {{part.show_all[language]}}
            </div>
            <div class="part__filter_region_more"
                 @click="setShowRegions(false)"
                 v-if="showAllRegions">
              {{part.hide[language]}}
            </div>
          </div>
        </div>
        <div class="part__filter_container"
             v-if="filter.type === 'calendar' && filtersActive[index]"
        >
          <Calendar @setDate="addFilter('calendar', null, $event, 'value')" />
        </div>
        <div class="part__filter_container"
             v-if="filter.type === 'from_to' && filtersActive[index]"
        >
          <a-slider range :default-value="[0, 100]"
                    @change="fromToChange"
                    :tip-formatter="priceFormatter" />
          <a-input-group compact>
            <a-input v-model="filter.values[0]" style="width: 50%" />
            <a-input v-model="filter.values[1]" style="width: 50%" />
          </a-input-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import part from '@/assets/text/part.json';

const I5Button = () => import('@/components/i5Button/I5Button.vue');
const I5CheckBox = () => import('@/components/checkbox/CheckBoxI5.vue');
const Stars = () => import('@/components/stars/Stars.vue');
const Calendar = () => import('@/components/calendar/Calendar.vue');

export default {
  name: 'Filters',
  data() {
    return {
      part,
      filtersActive: null,
      rating: 0,
      fromTo: null,
      fromToSliderMin: 0,
      fromToSliderMax: 100,
      filterTextClass: {
        letterSpacing: '-0.02em',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '18px',
      },
      showAllRegions: false,
      filters: {},
    };
  },
  methods: {
    filterClick(index, value) {
      this.$store.commit('part/setFilterState', {
        id: index,
        value,
      });
    },
    addFilter(name, id, value, type) {
      if (type === 'array') {
        if (!this.filters[name]) {
          this.filters[name] = [];
        }
        const index = this.filters[name].findIndex((item) => item === id);
        if (value === true && index < 0) {
          this.filters[name].push(id);
        } else {
          this.filters[name].splice(index, 1);
        }
      } else if (type === 'value') {
        this.filters[name] = value;
      }
      this.$emit('filter', this.filters);
    },
    setRating(value) {
      this.rating = value;
      this.addFilter('rating', null, value, 'value');
    },
    setShowRegions(value) {
      this.showAllRegions = value;
    },
    fromToChange(value) {
      this.fromToSliderMin = value[0];
      this.fromToSliderMax = value[1];
    },
    priceFormatter(value) {
      let result = 0;
      if (value === 0) {
        result = this.fromTo[0];
      } else if (value === 100) {
        result = this.fromTo[1];
      } else {
        const one = (+this.fromTo[1] - +this.fromTo[0]) / 100;
        result = Math.round(value * one);
      }
      return result;
    },
  },
  computed: {
    objectFilters() {
      return this.$store.state.part.objectFilters;
    },
    language() {
      return this.$store.state.index.language;
    },
  },
  mounted() {
    this.filtersActive = this.$store.state.part.openFilters;
    this.$store.watch((state) => state.part.openFilters, () => {
      this.filtersActive = this.$store.state.part.openFilters;
      this.$store.state.part.filters.forEach((filter) => {
        if (filter.type === 'from_to') {
          this.fromTo = filter.values;
        }
      });
    });
  },
  components: {
    I5Button,
    I5CheckBox,
    Stars,
    Calendar,
  },
};
</script>
